/* global $ jQuery */

jQuery.easing.def = 'easeOutQuad'

$(document).ready(function () {
  $('#lien-musique').css('fill', '#FFF')
  $('.projectThumbnail').on('mouseenter', function (e) {
    console.log('la')
    $(this).children('.projectThumbnailHover').fadeIn(300)

    $(this).children('.projectThumbnailHover').find('h4').css('display', 'block')
    $(this).children('.projectThumbnailHover').find('h4').css('opacity', '0')
    $(this).children('.projectThumbnailHover').find('h4').delay(100).animate({top: '10', opacity: 1}, 500)

    $(this).children('.projectThumbnailHover').find('h5').css('display', 'block')
    $(this).children('.projectThumbnailHover').find('h5').css('opacity', '0')
    $(this).children('.projectThumbnailHover').find('h5').delay(100).animate({left: '55%', opacity: 1}, 200)
    $(this).children('.projectThumbnailHover').find('h6').css('display', 'block')
    $(this).children('.projectThumbnailHover').find('h6').css('opacity', '0')
    $(this).children('.projectThumbnailHover').find('h6').delay(100).animate({left: '32%', opacity: 1}, 200)
  })

  $('.projectThumbnail').on('mouseleave', function (e) {
    $(this).children('.projectThumbnailHover').fadeOut(200)
    $(this).children('.projectThumbnailHover').find('h4').animate({top: '0', opacity: 0}, 0)
    $(this).children('.projectThumbnailHover').find('h4').animate({left: '450', opacity: 0}, 0)
    $(this).children('.projectThumbnailHover').find('h5').animate({left: '100%', opacity: 0}, 0)
    $(this).children('.projectThumbnailHover').find('h6').animate({left: '0%', opacity: 0}, 0)
  })
})
