$('#savoir-fleche1').click(function () {
  var st = $(window).scrollTop()

  $('html, body').animate({scrollTop: st + 500 }, { duration: 600 })
  $('#savoir-fleche1').animate({scrollTop: st + 500 }, { duration: 5000 })
})

$('#savoir-flechehaut').click(function () {
  var st = $(window).scrollTop()

  $('html, body').animate({scrollTop: 0 }, { duration: 600 })
  $('#savoir-fleche1').animate({scrollTop: 0 }, { duration: 5000 })
})

$(document).ready(function () { window.dispatchEvent(new Event('resize')) })
