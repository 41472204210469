$('#burger').click(function () {
  $('#about-mobile').fadeIn('fast')
  // lock scroll position, but retain settings for later
  // $('#main-wrapper').css('overflow','hidden')
  $('html, body').animate({
    scrollTop: 0
  }, {
    duration: 500
  })
})

$('#close-about-mobile').click(function () {
  $('#about-mobile').fadeOut('fast')
  // lock scroll position, but retain settings for later
  // $('#main-wrapper').css('overflow','hidden')
  $('html, body').animate({
    scrollTop: 0
  }, {
    duration: 500
  })
})
